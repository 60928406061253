import { Link } from 'gatsby';
import React from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Block from '../../components/ui/Cards/Block';
import CopyIcon from '../../components/ui/CopyIcon/CopyIcon';
import KofiWidget from '../../components/ui/KofiWidget/KofiWidget';
import { Direction } from '../../globals';
import './support.scss';

const support: React.FC = () => {
    return (
        <PageLayout>
            <PageSection className="support-section">
                <Container>
                    <Group size={2}>
                        <Block direction={Direction.vertical}>
                            <h1>Help us continue writing</h1>
                            <h4>
                                We greatly appreciate any donations, which will help us cover hosting costs and other expenses. We write
                                on our free time so any contribution matters.
                            </h4>
                            <h4>
                                If you prefer a monthly contribution, you can
                                become{' '}
                                <a
                                    href="https://www.patreon.com/bePatron?u=29800308"
                                    data-patreon-widget-type="become-patron-button"
                                >
                                    one of our patreons
                                </a>
                                . Or if you are a crypto geek like us, we accept
                                various coins as you can see below.
                            </h4>

                            <h4>
                                However if you don't feel like donating, you can support
                                us by buying our course or a copy of our books. 
                            </h4>
                            <h4>
                                If you have any questions, feel free to contact
                                us. Thank you in advance.
                            </h4>
                        </Block>
                        <Block direction={Direction.vertical}>
                            <KofiWidget />
                        </Block>
                    </Group>

                    <Block direction={Direction.vertical}>
                        <h2>Support us with crypto</h2>
                        <table>
                            <tr>
                                <td>Bitcoin</td>
                                <td>3LMk7QC6zYTFYBrBt5SXAwo1bZSnipAbNg</td>
                                <td>
                                    <CopyIcon text="3LMk7QC6zYTFYBrBt5SXAwo1bZSnipAbNg" />
                                </td>
                            </tr>
                            <tr>
                                <td>Ethereum</td>
                                <td>
                                    0x954172bf6c41a77ff35caBaF7a5ED7eA419393ba
                                </td>
                                <td>
                                    <CopyIcon text="0x954172bf6c41a77ff35caBaF7a5ED7eA419393ba" />
                                </td>
                            </tr>
                            <tr>
                                <td>Litecoin</td>
                                <td>MTvy8yPdkcCU9ppMD6DoCEXswssDaeEbuc</td>
                                <td>
                                    <CopyIcon text="MTvy8yPdkcCU9ppMD6DoCEXswssDaeEbuc" />
                                </td>
                            </tr>
                            <tr>
                                <td>Cardano</td>
                                <td>
                                    addr1v9rzmrqe3nlwv4295dfzur3ewvh43e60tndz30payjl9pesktlyrg
                                </td>
                                <td>
                                    <CopyIcon text="addr1v9rzmrqe3nlwv4295dfzur3ewvh43e60tndz30payjl9pesktlyrg" />
                                </td>
                            </tr>
                            <tr>
                                <td>Chainlink</td>
                                <td>
                                    0x7624C9C76210db1F81EF24AB24129c45984015E3
                                </td>
                                <td>
                                    <CopyIcon text="0x7624C9C76210db1F81EF24AB24129c45984015E3" />
                                </td>
                            </tr>
                        </table>

                        <p>
                            For any other coin, feel free to{' '}
                            <Link to="/contact/">drop us a message</Link>
                        </p>
                    </Block>
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export default support;
